export class FileManagerFakeDb
{
    public static files = [
        {
            'name'     : 'Name1',
            'type'     : 'Admin',
            'owner'    : 'All',
            'size'     : '',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'write'    : true,
            'location' : 'New Delhi',
            'read'  : true
        },
        {
            'name'     : 'Name 2',
            'type'     : 'Admin',
            'owner'    : 'Read, Write',
            'size'     : '',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'write'    : false,
            'location' : 'New Delhi',
            'read'  : true
        },
        {
            'name'     : 'Name 3',
            'type'     : 'User',
            'owner'    : 'read',
            'size'     : '',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'write': false,
            'location' : 'New Delhi',
            'read'  : true
        },
        {
            'name'     : 'Name4',
            'type'     : 'partner',
            'owner'    : 'Read+Write',
            'size'     : '1.2 Mb',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'write': true,
            'location' : 'New Delhi',
            'read'  : false
        },
        {
            'name'     : 'Name1',
            'type'     : 'Admin',
            'owner'    : 'All',
            'size'     : '',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'write'    : true,
            'location' : 'New Delhi',
            'read'  : true
        },
        {
            'name'     : 'Name 2',
            'type'     : 'Admin',
            'owner'    : 'Read, Write',
            'size'     : '',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'write'    : false,
            'location' : 'New Delhi',
            'read'  : true
        },
        {
            'name'     : 'Name 3',
            'type'     : 'User',
            'owner'    : 'read',
            'size'     : '',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'write': false,
            'location' : 'New Delhi',
            'read'  : true
        },
        {
            'name'     : 'Name4',
            'type'     : 'partner',
            'owner'    : 'Read+Write',
            'size'     : '1.2 Mb',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'write': true,
            'location' : 'New Delhi',
            'read'  : false
        }
    ];

}
