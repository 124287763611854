/**
 * Created By: Ankit Sharma
 * Date :
 * @ info.ankitamc@gmail.com
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';

import { LoginData } from './login.model';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {

    // private
    private isAuthenticated = false;
    private token: string;
    private tokenTimer: any;
    private authStatusListener = new Subject<boolean>();

    baseURL = environment.baseUrl;

    

    who: any;
    errors: any;
    errCode: any;
    id: any;
    user: any;
    email: any;
    permissions: [];
    companyID: any;
    role: any;
    sdata: any;

    constructor(private http: HttpClient, 
                private router: Router
               ) { }

    // to get token
    getToken(): any {
        return this.token;
    }

    isError(): void {
        return this.errors;
    }

    // To get user is Authenticated or not
    getIsAuth(): any {
        return this.isAuthenticated;
    }

    // to get user details from local storage 
    getUser(): any {
        const user = this.getAuthData();
        return user;
    }

    // To get who field of user
    // Todo enchance the who feature in side-nav-bar
    getWho(): any {
        return this.who;
    }
    
    // Auth listener
    getAuthStatusListener(): any {
        return this.authStatusListener.asObservable();
    }


     /**
      * To login user and call local storage functions to store data into it
      * @param email
      * @param password
      */
    login(email: string, password: string): any {
        // console.log(this.baseURL);
        const authData: LoginData = { email: email, password: password };
        this.http.post<any>( this.baseURL + 'login', authData)
            .subscribe((response) => {
                console.log(response);
                const token = response.data.api_token;
                this.token = token;
                this.companyID = response.data.company_id;
                this.user = response.data.name;
                this.email = response.data.email;
                this.permissions = response.data.permissions;
                this.sdata = response.data.status;
                this.role = response.data.role[0].toLowerCase();
                if (token) {
                    // todo: enchance the Expire in time of token at frontend
                    const expiresInDuration = response.expiresIn;
                    this.isAuthenticated = true;
                    this.authStatusListener.next(true);
                    const now = new Date();
                    const expirationDate = new Date(now.getTime() + 1000 * 10000);

                    this.saveAuthData(token, expirationDate, this.id, this.companyID, this.user, this.email, this.role, this.permissions, this.sdata);
                    
                    if (this.role === 'super_admin'){
                        // If everything OK, navivagate to admin Dashboard
                        // console.log('dash')
                        this.router.navigate(['apps/dashboard/admin']);
                    }else if (this.role === 'partner_admin') {
                        // If everything OK, navivagate to partner Dashboard
                        this.router.navigate(['apps/dashboard/partner']);
                    }else if (this.role === 'client_admin') {
                        // If everything OK, navivagate to client study Dashboard
                        this.router.navigate(['apps/dashboard/client']);
                    }
                }

            },
                (error) => {
                    this.authStatusListener.next(false);
                    // console.log(error.status);
                    if (error.status === 401) {
                        this.errCode = error.status;
                    }
                });
    }


    autoAuthUser(): void {
        console.log('auto login call');
        const authInformation = this.getAuthData();
        if (!authInformation) {
            return;
        }
        const now = new Date();
        const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
        if (expiresIn > 0) {
            // const who = localStorage.getItem('who');
            // if (who === 'super_admin'){
            //     this.router.navigate(['apps/dashboard/admin']);
            // }else if (who === 'partner_admin') {
            //     this.router.navigate(['apps/dashboard/partner']);
            // }else if (who === 'client_admin') {
            //     this.router.navigate(['apps/dashboard/client']);
            // }
            this.router.navigate([localStorage.getItem('current_route')]);
            this.token = authInformation.token;
            this.isAuthenticated = true;
            this.setAuthTimer(expiresIn / 1000);
            this.authStatusListener.next(true);
        }
    }

    // to logout a user
    logout(): void {
        this.token = null;
        this.isAuthenticated = false;
        this.authStatusListener.next(false);
        clearTimeout(this.tokenTimer);
        this.clearAuthData();
        this.router.navigate(['/']);
    }


    private setAuthTimer(duration: number): void {
        this.tokenTimer = setTimeout(() => {
            this.logout();
        }, duration * 1000);
    }

    // To save the user data in local storage to avoid relogin problem
    private saveAuthData(token: string, expirationDate: Date, id: string, companyID: string, user: string, email: string, role: any, permissions: any, sdata: any): void {
        localStorage.setItem('token', token);
        localStorage.setItem('id', id);
        localStorage.setItem('companyID', companyID);
        localStorage.setItem('user', user);
        localStorage.setItem('email', email);
        localStorage.setItem('who', role);
        localStorage.setItem('permissions', JSON.stringify(permissions));
        localStorage.setItem('sdata', JSON.stringify(sdata));
        localStorage.setItem('expiration', expirationDate.toISOString());
    }

    // To clear user data from local Storage after user log out
    private clearAuthData(): void {
        localStorage.removeItem('token');
        localStorage.removeItem('expiration');
        localStorage.removeItem('id');
        localStorage.removeItem('companyID');
        localStorage.removeItem('user');
        localStorage.removeItem('email');
        localStorage.removeItem('who');
        localStorage.removeItem('permissions');
        // Remove parent id which is declared in company component
        localStorage.removeItem('parent_id');
        // Removing more values

        localStorage.removeItem('admin_ref');
        localStorage.removeItem('partner_ref');
        localStorage.removeItem('current_route');
        localStorage.removeItem('previous_route');
        localStorage.removeItem('partnerID');
        localStorage.clear();
    }


    // To get user data on demand if user data is saved in local storage 
    private getAuthData(): any {
        const token = localStorage.getItem('token');
        const expirationDate = localStorage.getItem('expiration');
        const id = localStorage.getItem('id');
        const companyID = localStorage.getItem('companyID');
        const user = localStorage.getItem('user');
        const email = localStorage.getItem('email');
        const who = localStorage.getItem('who');
        const permissions = localStorage.getItem('permissions');
        if (!token || !expirationDate) {
            return;
        }
        return {
            token: token,
            expirationDate: new Date(expirationDate),
            id: id,
            companyID: companyID,
            user: user,
            email: email,
            who: who,
            permissions: permissions
        };
    }
}
