export class PartnersFakeDb
{
    public static partners = [
        {
            id      : '00001',
            name    : 'Unni',
            lastName: 'Baskar',
            avatar  : 'assets/images/avatars/Abbott.jpg',
            company : 'OrgLens',
            slug    : 'orglens',
            email   : 'abbott@withinpixels.com',
            phone   : '+1-202-555-0175',
            address : '933 8th Street Stamford, CT 06902'
        },
        {
            id      : '00002',
            name    : 'Ankit',
            lastName: 'Sharma',
            avatar  : 'assets/images/avatars/Arnold.jpg',
            company : 'CafeTechMedia',
            slug    : 'cafetech-media',
            email   : 'arnold@withinpixels.com',
            phone   : '+1-202-555-0141',
            address : '906 Valley Road Michigan City, IN 46360'
        },
        {
            id      : '00003',
            name    : 'Abhishek',
            lastName: 'B',
            avatar  : 'assets/images/avatars/Barrera.jpg',
            company : 'ODA',
            slug    : 'oda',
            email   : 'barrera@withinpixels.com',
            phone   : '+1-202-555-0196',
            address : '183 River Street Passaic, NJ 07055'
        },
        {
            id      : '00004',
            name    : 'Mohan',
            lastName: 'Kumar',
            avatar  : 'assets/images/avatars/Blair.jpg',
            company : 'IpToilet',
            slug    : 'ip-toilet',
            email   : 'blair@withinpixels.com',
            phone   : '+1-202-555-0118',
            address : '143 Jones Street Eau Claire, WI 54701'
        },
        {
            id      : '00005',
            name    : 'Vaishali',
            lastName: 'Gambhir',
            avatar  : 'assets/images/avatars/Boyle.jpg',
            company : 'Od Alternavtives',
            slug    : 'od-alternatives',
            email   : 'boyle@withinpixels.com',
            phone   : '+1-202-555-0177',
            address : '218 Pearl Street Brandon, FL 33510'
        }
    ];

    public static clients = [
        {
            id      : '5725a680b3249760ea21de52',
            name    : 'Abbott',
            lastName: 'Keitch',
            avatar  : 'assets/images/avatars/Abbott.jpg',
            company : 'Saois', 
            email   : 'abbott@withinpixels.com',
            phone   : '+1-202-555-0175',
            address : '933 8th Street Stamford, CT 06902'
        },
        {
            id      : '5725a680606588342058356d',
            name    : 'Arnold',
            lastName: 'Matlock',
            avatar  : 'assets/images/avatars/Arnold.jpg',
            company : 'Laotcone',
            email   : 'arnold@withinpixels.com',
            phone   : '+1-202-555-0141',
            address : '906 Valley Road Michigan City, IN 46360'
        },
        {
            id      : '5725a68009e20d0a9e9acf2a',
            name    : 'Barrera',
            lastName: 'Bradbury',
            avatar  : 'assets/images/avatars/Barrera.jpg',
            company : 'Unizim',
            email   : 'barrera@withinpixels.com',
            phone   : '+1-202-555-0196',
            address : '183 River Street Passaic, NJ 07055'
        },
        {
            id      : '5725a6809fdd915739187ed5',
            name    : 'Blair',
            lastName: 'Strangeway',
            avatar  : 'assets/images/avatars/Blair.jpg',
            company : 'Conedubdax',
            email   : 'blair@withinpixels.com',
            phone   : '+1-202-555-0118',
            address : '143 Jones Street Eau Claire, WI 54701'
        },
        {
            id      : '5725a68007920cf75051da64',
            name    : 'Boyle',
            lastName: 'Winters',
            avatar  : 'assets/images/avatars/Boyle.jpg',
            company : 'Newo',
            email   : 'boyle@withinpixels.com',
            phone   : '+1-202-555-0177',
            address : '218 Pearl Street Brandon, FL 33510'
        }
    ];

    public static users = [ 
        {
            id      : '5725a680b3249760ea21de52',
            name    : 'Unni',
            lastName: 'Bhaskar',
            avatar  : 'assets/images/avatars/Abbott.jpg',
            company : 'Saois', 
            email   : 'abbott@withinpixels.com',
            phone   : '+1-202-555-0175',
            address : '933 8th Street Stamford, CT 06902'
        },
        {
            id      : '5725a680606588342058356d',
            name    : 'Vaishali',
            lastName: 'Gambhir',
            avatar  : 'assets/images/avatars/Arnold.jpg',
            company : 'Laotcone',
            email   : 'arnold@withinpixels.com',
            phone   : '+1-202-555-0141',
            address : '906 Valley Road Michigan City, IN 46360'
        },
        {
            id      : '5725a68009e20d0a9e9acf2a',
            name    : 'Abhishek',
            lastName: 'B',
            avatar  : 'assets/images/avatars/Barrera.jpg',
            company : 'Unizim',
            email   : 'barrera@withinpixels.com',
            phone   : '+1-202-555-0196',
            address : '183 River Street Passaic, NJ 07055'
        },
        {
            id      : '5725a6809fdd915739187ed5',
            name    : 'Ankit',
            lastName: 'Sharma',
            avatar  : 'assets/images/avatars/Blair.jpg',
            company : 'Conedubdax',
            email   : 'blair@withinpixels.com',
            phone   : '+1-202-555-0118',
            address : '143 Jones Street Eau Claire, WI 54701'
        },
        {
            id      : '5725a68007920cf75051da64',
            name    : 'Mohan',
            lastName: 'Kumar',
            avatar  : 'assets/images/avatars/Boyle.jpg',
            company : 'Newo',
            email   : 'boyle@withinpixels.com',
            phone   : '+1-202-555-0177',
            address : '218 Pearl Street Brandon, FL 33510'
        }
    ];
}
