import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Applications',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        who      : '',
        icon     : 'apps',
        children : [
            {
                id       : 'dashboards',
                title    : 'Dashboards',
                translate: 'NAV.DASHBOARDS',
                type     : 'item',
                who      : '',
                icon     : 'dashboard',
                url  : '/apps/dashboards/project'
            },
            {
                id       : 'studies',
                title    : 'Studies',
                translate: 'NAV.ACADEMY',
                type     : 'item',
                who      : 'client',
                icon     : 'school',
                url      : '/apps/study'
            },
            {
                id       : 'partners',
                title    : 'Add Study',
                translate: 'NAV.ACADEMY',
                type     : 'item',
                who      : 'admin',
                icon     : 'view_compact',
                url      : '/apps/study/form'
            }
        ]
    }
];
