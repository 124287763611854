/**
 * Created By: Ankit Sharma
 * Date :
 * @ info.ankitamc@gmail.com
 */

import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): any {
        const authToken = this.authService.getToken();
        const authRequest = req.clone({
            // sen dauthorization header in the sequest url
            headers: req.headers.set('Authorization', 'Bearer ' + authToken)
        });
        return next.handle(authRequest);
    }
}
