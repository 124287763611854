/**
 * Created By: Ankit Sharma
 * Date :
 * @ info.ankitamc@gmail.com
 */

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, RoutesRecognized} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';
import { filter, pairwise } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | Observable<boolean> | Promise<boolean> {
        const isAuth = this.authService.getIsAuth();
        if (!isAuth) {
            console.log(route);
            this.router.events
            .pipe(filter((e: any) => e instanceof RoutesRecognized),
                pairwise()
            ).subscribe((e: any) => {
                console.log(e[1].urlAfterRedirects);
            });
            this.router.navigate(['/']);
        }
        return isAuth;
    }
}
